@media screen and (min-width: 768px) {
    .logo {
        text-align: center;
        color: rgb(104, 104, 104);
        font-weight: 800;
        letter-spacing: 0.1ch;
        font-size: 1.8em;
    }

    .trademark {
        text-align: center;
        color: rgb(85, 85, 85);
        font-size: 1.1em;
    }

    .navbar:hover .logo {
        transform: rotate(-180deg);
        transition: var(--transition);
        color: var(--text-secondary)
    }

    .navbar {
        margin: 0;
        padding: 0;
        display: flex;
        width: 6em;
        height: 100vh;
        position: fixed;
        background-color: black;
        border-right: 3px solid var(--text-secondary);
        bottom: 0em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 5em;
        transition: var(--transition);
    }

    .nav-link {
        color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3em;
        font-size: 1.3em;
    }

    .nav-link:hover {
        background-color: rgba(53, 53, 53, 0.491);
        width: 100%;
        align-items: center;
        transition: var(--transition);
        color: var(--text-secondary);
    }

    .link-text {
        display: none;
        transition: var(--transition);
    }

    .navbar:hover {
        width: 12em;
        transition: var(--transition);
    }

    .navbar:hover .link-text {
        display: block;
        transition: var(--transition);
    }

}


@media screen and (max-width: 768px) {
    .nav-container {
        display: block;
        width: 65%;
        background-color: rgba(35, 35, 35, 0.539);
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1rem;
        z-index: 2;
        display: flex;
        left: 50%;
        transform: translateX(-50%);
        backdrop-filter: blur(8px);
        gap: 2.5rem;
        border-radius: 10px;
    }

    nav a {
        display: flex;
        background: transparent;
        padding: 0.5rem;
        color: rgb(252, 252, 252);
        font-size: 1.6em;
    }

    nav a:hover {
        color: var(--text-secondary);
        transition: all 400ms ease;
    }

    .link-text,
    .logo,
    .trademark {
        display: none;
    }
}