.portfolio__container {
    margin: 0 auto;
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
}

.portfolio__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.8em;
}

h1 {
    height: 2em;
}

article h3 {
    font-weight: 600;
    color: var(--color-primary);
}

article p {
    margin-top: -1.6em;
    text-align: justify;
    padding: 0.6em;
    line-height: 1.3em;
}

.portfolio-item {
    width: 470px;
    background-color: rgba(13, 25, 39, 0.057);
    padding: 0.3em;
    border: 1.1px solid black;
}


.img-container {
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}


.btn-container {
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    padding: 0.1em;
    margin-top: 0.3em;
}

.btn {
    display: inline-flex;
    color: white;
    padding: 0.55em 1.2em;
    border-radius: 0.2em;
    transition: all 400ms ease;
    background-color: var(--color-primary);
    cursor: pointer;
    width: 5em;
}

.btn-secondary {
    display: inline-flex;
    color: rgb(0, 0, 0);
    padding: 0.55em 1.2em;
    transition: all 400ms ease;
    cursor: pointer;
    border: 1px solid var(--color-secondary);
}

.btn:hover {
    cursor: pointer;
    border: 1px solid;
    background-color: rgba(0, 255, 255, 0.149);
    transition: var(--transition);
    color: black;
}

.btn-secondary:hover {
    cursor: pointer;
    border: 1px solid;
    background-color: rgba(0, 255, 255, 0.149);
    transition: var(--transition);
}

/*----------Mobile------------ **/
@media screen and (max-width: 768px) {
    .portfolio-item {
        width: 74%;
        background-color: rgba(13, 25, 39, 0.057);
        padding: 0.1em;
        border: 1.1px solid black;

    }

    .portfolio__container {
        margin-top: 5em;
        margin-bottom: 3em;
        margin-left: -0.5em;
    }

    .portfolio__wrapper {
        margin-left: -2.3em;
    }

    h1 {
        margin-left: 1.3em;
    }

    .divider {
        display: none;
    }
}