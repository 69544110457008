@import url('https://fonts.googleapis.com/css2?family=Inconsolata&family=Roboto+Condensed:wght@700&family=Roboto:wght@500&display=swap');

:root {
  --transition: all 400ms ease;
  --color-primary: rgba(6, 71, 163, 0.64);
  --color-secondary: rgb(1, 54, 227);
  --text-secondary: deepskyblue;

}

body {
  font-family: 'Inconsolata', monospace;
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  border: 0;
}

.divider {
  margin-top: 5em;
  margin-bottom: 2em;
  text-align: center;
  font-size: 1.4em;
}

h1 {
  font-size: 150%;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}