.container__contact {
    margin: 0 auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    margin-top: 10em;
}

.container-flex {
    display: flex;
    margin-top: 1.9em;
    padding: 0.6em;
    flex-direction: row;
    justify-content: space-around;
    height: 75%;
}

.container-info {
    background-color: rgb(0, 0, 0);
    color: white;
    flex: 1;
    line-height: 5.9em;
}

.container-query {
    width: 100%;
    flex: 1;
    line-height: 6em;
    border: 1px solid black;
}

.form_query {
    color: white;
    text-align: center;
    margin-top: 5.1em;
}

::placeholder {
    color: rgb(12, 12, 12);
    padding: 0.8em;
    opacity: 0.5;
    font-weight: 600;
    font-size: 1.1em;
}

.container-info p {
    margin-left: 5em;
    font-size: 1.1em;
    margin-bottom: -1.1em;
}

.container-info a {
    color: rgb(255, 255, 255);
    margin-left: 5em;
    transition: var(--transition);
    font-size: 1.1em;
}

.container-info a:hover {
    color: var(--text-secondary);
}

input,
textarea {
    border-color: 0.6px solid black;
    border-radius: 0.4em;
    height: 2.8em;
    width: 25em;
    padding: 12px 20px;
    margin: 8px 0;
}

input:hover,
textarea:hover {
    border: 2px solid var(--text-secondary);
    background-color: rgba(0, 255, 255, 0.023);
    transition: var(--transition);
}

textarea {
    margin-top: 2em;
}

.btn-cv {
    color: rgb(0, 0, 0);
    padding: 0.55em 1.2em;
    border-radius: 0.2em;
    transition: var(--transition);
    border: 1px solid white;
    cursor: pointer;
    width: 20em;
    margin-left: 6em;
    font-weight: 500;
    background-color: var(--text-secondary);
}

.btn-submit {
    color: rgb(0, 0, 0);
    padding: 0.55em 1.2em;
    border-radius: 0.2em;
    transition: var(--transition);
    border: 2px solid rgb(70, 70, 70);
    cursor: pointer;
    font-weight: 500;
    width: 20em;
    background-color: var(--text-secondary);
}

.btn-submit:hover {
    cursor: pointer;
    color: white;
    border: 1px solid white;
    background-color: var(--color-primary);
}

.btn-cv:hover {
    cursor: pointer;
    color: white;
    border: 1px solid;
    background-color: var(--color-primary);
}

.info {
    color: white;
    margin-bottom: 3.1em;
    margin-left: 2.8em;
}

.query {
    color: black;
    text-align: right;
    margin-right: 2.8em;
    margin-top: 1em;
    margin-bottom: 3.9em;
}

/*----------Mobile------------ **/
@media screen and (max-width: 768px) {
    .container__contact {
        margin-left: 1.8em;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 3em;
    }

    .container-info {
        background-color: rgb(0, 0, 0);
        color: white;
        flex: 1;
        line-height: 3em;
        font-size: 0.6em;
        text-align: center;
    }

    .container-query {
        flex: 1;
        line-height: 2.5em;
        border: 1px solid black;
        text-align: center;
    }

    .container-info p {
        margin-left: 0em;
        font-size: 1.3em;
    }

    .container-info a {
        line-height: 7.2em;
        color: rgb(255, 255, 255);
        margin-left: 0em;
        transition: var(--transition);
        font-size: 1.3em;
    }

    .info {
        margin-top: 2.3em;
        color: white;
        margin-left: 1.1em;
        text-align: center;
        margin-right: 0.8em;
        font-size: 1.9em;
        font-weight: 100;
    }

    .query {
        text-align: center;
        margin-right: 0.8em;
        font-size: 1.2em;
        line-height: 1.4em;
    }

    .form_query {
        margin-top: 4em;
        text-align: center;
    }

    input,
    textarea {
        border-color: 0.6px solid black;
        border-radius: 0.4em;
        height: 1.6em;
        width: 8em;
        padding: 12px 20px;
        margin: 8px 0;
    }

    textarea {
        margin-top: 2em;
    }

    .btn-cv {
        width: 14em;
        margin-left: 0.4em;
        font-size: 1.4em;
    }

    .btn-submit {
        width: 12em;
    }

    .container-flex {
        height: 85vh;
    }

    ::placeholder {
        padding: 0.8em;
        opacity: 0.5;
        font-weight: 600;
        font-size: 1.0em;
    }
}