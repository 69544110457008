.header__container {
    margin: 0 auto;
    width: 65%;
    height: 45vh;
    line-height: 2.2em;
    display: flex;
    margin-top: 10em;
    margin-bottom: 10em;
    flex-direction: column;

}

.wrapper {
    display: inline-block;
    height: 2.20em;
    width: 30em;
}

h2 {
    height: 0em;
    color: var(--text-secondary);
    font-size: 1.6em;
    font-weight: 800;
    margin-left: 1.2em;
    margin-top: 2em;
}

h4 {
    padding-left: 4em;
    justify-content: center;
    line-height: 2.8em;
    font-size: 1.1em;
}

.stack {
    color: rgb(48, 48, 48);
    font-style: italic;
    font-size: 1.2em;
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid rgb(0, 0, 0);
    white-space: nowrap;
    animation:
        typing 4.5s steps(20, end) forwards infinite;


    font-size: 2.1rem;
    width: 0;

}

@keyframes typing {
    0% {
        width: 0%
    }

    50% {
        width: 100%
    }

    100% {
        width: 100%
    }
}

/*----------Mobile------------ **/
@media screen and (max-width: 768px) {
    .header__container {
        display: flex;
        width: 100%;
        height: 50vh;
        line-height: 2.2em;
        flex-direction: column;
        align-items: center;
        margin-bottom: 23em;
    }

    h4 {
        padding-left: 2.9em;
    }

    .typed-out {
        font-size: 1.7rem;
    }

    .wrapper {
        margin-left: 9em;
    }

    .divider {
        display: none;
    }


}